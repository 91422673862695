<template>
  <div>
    <van-nav-bar :title="$route.meta.title" left-arrow @click-left="toStudent" />
    <van-cell-group v-for="(sdt,index) in dataList" :key="index" :title="sdt.cnName">
      <template v-if="sdt.contractVoList.length > 0">
        <van-cell v-for="(item, idx) in sdt.contractVoList" :key="idx" :title="'合同编号：' + item.number" value="详情" is-link center @click="toDetail(item)">
          <template #label>
            <div>合同金额：{{ item.amount }} </div>
            <div>课时总计：{{ item.totalClassHour }}课时</div>
          </template>
        </van-cell>
      </template>
      <template v-else>
        <van-cell title="暂未签署合同" center />
      </template>
    </van-cell-group>
  </div>
</template>
<script>
import guardianApi from '@/api/guardian'
export default {
  data() {
    return {
      appId: '',
      dataList: []
    }
  },
  created() {
    this.appId = this.$route.params.appId
  },
  mounted() {
    this.initDataList()
  },
  methods: {
    initDataList() {
      guardianApi.contractList().then(res => {
        if (res.succ) {
          this.dataList = res.data.items
        }
      })
    },
    toDetail(item) {
      this.$router.push(`/${this.appId}/guardian/contract/detail/${item.id}`)
    },
    toStudent() {
      this.$router.push(`/${this.appId}/guardian`)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
